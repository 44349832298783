<template>
  <div>
    <b-form-group id="input-group-1" label="Plan Name:" label-for="input-1">
      <b-form-input id="input-1" v-model="form.name_en"></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-2" label="Plan Name 
        (AR):" label-for="input-2">
      <b-form-input id="input-2" v-model="form.name_ar"></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-3" label="Price: 
        " label-for="input-3">
      <b-form-input id="input-3" type="number" v-model="form.price"></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-3" label="Currency: 
        " label-for="input-3">
      <b-form-select v-model="selectedCurrency" :options="currencyOptions"></b-form-select>
    </b-form-group>
    <b-form-group id="input-group-3" label="Frequency: 
        " label-for="input-3">
      <b-form-select v-model="selectedFrequency" :options="frequencyOptions"></b-form-select>
    </b-form-group>
    <b-form-group id="input-group-4" label="Free Trial Days" label-for="input-4">
      <b-form-input id="input-4" type="number" v-model="form.freeTrial"></b-form-input>
    </b-form-group>

    <!-- <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.checked"
          id="checkboxes-4"
          :aria-describedby="ariaDescribedby"
        >
          <b-form-checkbox value="that">Includes Free Trial?</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group> -->

    <b-table title="Features" :items="features"><template v-slot:cell(actions)="row">
        <i @click="deleteRow(row.index)" id="deleteIconBtn" class="ri-delete-bin-fill"></i>
        <b-tooltip target="deleteIconBtn" triggers="hover">
          Delete Feature
        </b-tooltip>
      </template>
    </b-table>

    <h6>Add Feature</h6>
    <div id="featuresBox" class="justify-content-center">
      <div class="row">
        <div class="col-3">
          <label for="input-5">Name</label>
        </div>
        <div class="col-9">
          <b-form-input id="input-5" v-model="featureModel.name_en"></b-form-input>
        </div>

        <div class="col-3 mt-3">
          <label for="input-5">Name (AR)</label>
        </div>
        <div class="col-9 mt-3">
          <b-form-input id="input-5" v-model="featureModel.name_ar"></b-form-input>
        </div>

        <div class="col-3 mt-3">
          <label for="input-5">Status</label>
          <i id="tooltip-target-1" class="ri-question-fill ml-2 mt-1"></i>

          <b-tooltip target="tooltip-target-1" triggers="hover">
            Feature included/excluded in the plan
          </b-tooltip>
        </div>

        <div class="col-9 mt-3">
          <b-form-select v-model="selectedValue" :options="options"></b-form-select>
        </div>

        <div v-if="selectedValue == 'other'" class="col-3 mt-3">
          <label for="input-5">Value</label>
        </div>
        <div v-if="selectedValue == 'other'" class="col-9 mt-3">
          <b-form-input id="input-5" v-model="featureModel.value"></b-form-input>
        </div>
      </div>

      <div style="text-align: end; padding-top: 1em">
        <b-button @click="addFeature" type="submit" size="md" variant="primary">Add</b-button>
      </div>
    </div>
    <h6>Add Plan Level</h6>
    <div id="featuresBox" class="justify-content-center">
      <div class="row">
        <div class="col-3 mt-2 ">
          <label for="input-5">User Type</label>
        </div>
        <div class="col-9">
          <b-form-select v-model="selectedUserType" :options="user_types" v-on:change="getPlanLevels()"
            style=" margin-bottom: 20px;">
            <template #first>
              <b-form-select-option :value="null" disabled>
                <span>-- Select User Type --</span>
              </b-form-select-option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="row" v-if="selectedUserType != null">
        <div class="col-3 mt-3">
          <label for="input-5">Plan Level</label>
        </div>

        <div class="col-9">

          <b-form-select v-model="selectedPlanLevel" class="mt-2">
            <template #first>
              <b-form-select-option :value="null" disabled>
                <span v-if="plan_list_loading">Loading Please wait </span>
                <span v-else>-- Select Plan Level --</span>

              </b-form-select-option>

            </template>
            <b-form-select-option v-for="level in levelList" :key=level.id :value="level">
              {{ level.name }}
            </b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div style="text-align: end; padding-top: 1em" v-if="isLoading">
      <b-button variant="primary" disabled>
        <b-spinner small type="grow"></b-spinner>
        Loading...
      </b-button>
    </div>
    <div style="text-align: end; padding-top: 1em" v-else>
      <b-button @click="createPlan" class="float-right" type="submit" variant="primary">Submit</b-button>
    </div>
  </div>
</template>

<script>
import { plans } from "@/config/api/plans";
import { levels } from "@/config/api/levels";

export default {
  data() {
    return {
      isLoading: false,
      form: {
        name_en: "",
        name_ar: "",
        price: 0,
        freeTrial: 0,
        checked: [],
      },
      selectedFrequency: 1,
      isActiveFeature: true,
      features: [],
      levelList: [],
      user_types: [
        "user", "company"
      ],
      selectedUserType: null,
      selectedPlanLevel: null,
      plan_list_loading: null,
      selectedCurrency: "aed",
      featureModel: {
        name_en: "",
        name_ar: "",
        value: "",
      },
      selectedValue: "included",
      options: [
        { value: "included", text: "Included" },
        { value: "excluded", text: "Excluded" },
        { value: "unlimited", text: "Unlimited" },
        { value: "other", text: "Other" },
      ],
      currencyOptions: [
        { value: "aed", text: "AED" },
        { value: "usd", text: "USD" },
      ],
      frequencyOptions: [
        { value: "1", text: "1 Month" },
        { value: "2", text: "2 Month" },
        { value: "3", text: "3 Month" },
        { value: "4", text: "4 Month" },
        { value: "5", text: "5 Month" },
        { value: "6", text: "6 Month" },
        { value: "7", text: "7 Month" },
        { value: "8", text: "8 Month" },
        { value: "9", text: "9 Month" },
        { value: "10", text: "10 Month" },
        { value: "11", text: "11 Month" },
        { value: "12", text: "12 Month" },
      ],
    };
  },
  methods: {
    deleteRow(index) {
      this.features.splice(index, 1);
    },
    getPlanLevels() {
      this.plan_list_loading = true
      this.selectedPlanLevel = null
      const api = levels.getAll;
      if (this.selectedUserType != null) {
        api.params = { user_type: this.selectedUserType };

        this.generateAPI(api).then((res) => {
          this.levelList = res.data.plans;
          this.plan_list_loading = false
        });
      }
    },
    addFeature() {
      if (this.featureModel.name_en == "") {
        alert("Please enter the feature name");
        return;
      } else {
        var statusVal;
        if (this.selectedValue != "other") {
          statusVal = this.selectedValue;
        } else {
          statusVal = this.featureModel.value;
        }
        var nameAr = "";
        if (this.featureModel.name_ar != "") {
          nameAr = this.featureModel.name_ar;
        } else {
          nameAr = this.featureModel.name_en;
        }

        this.features.push({
          name_en: this.featureModel.name_en,
          name_ar: nameAr,
          value: statusVal,
          actions: true,
        });
        this.featureModel = {
          name_en: "",
          name_ar: "",
          value: "",
        };
      }
    },
    async createPlan() {
      try {
        this.isLoading = true;
        const api = plans.create;
        var formData = this.form;

        formData.features = this.features;
        formData.currency = this.selectedCurrency;
        formData.frequency = this.selectedFrequency;
        formData.plan_level = this.selectedPlanLevel._id;
        var validatedForm = this.validateForm(formData);

        if (validatedForm == false) {
          alert("Please fill all the required fields");
          this.isLoading = false;
        } else {
          api.data = formData;
          let res = await this.generateAPI(api);
          this.supportData = res.data;
          this.isLoading = false;
          this.$emit("success");
        }
      } catch (error) {
        console.error(error);
      }
    },
    validateForm(formData) {
      console.log(formData);

      if (formData.name_en == "" || formData.price == 0) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style>
#featuresBox {
  border: 0.002px dotted grey;
  border-radius: 12px;
  padding: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
