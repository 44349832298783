<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { plans } from "@/config/api/plans";
import AddPlan from "@/components/plans/add-plan";

export default {
  page: {
    title: "Plans",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    AddPlan,
  },

  data() {
    return {
      title: "Plans",
      items: [
        {
          text: "",
          // to: { name: "" },
        },
        {
          text: "Plans",
          active: true,
        },
      ],
      plansData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      activeTab: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "name_en", sortable: false, label: "Name" },
        { key: "price", sortable: false, label: "Price" },
        { key: "free_trial", sortable: false, label: "Free Trial" },
        { key: "plan_level", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "actions", sortable: false, label: "Actions" },
      ],
    };
  },
  async beforeMount() {
    await this.loadData();
  },
  computed: {
    rows() {
      return this.totalRows;
    },

    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
    userPlans() {
      return this.plansData.filter((plan) => plan.plan_level.user_type === 'user');
    },
    companyPlans() {
      return this.plansData.filter((plan) => plan.plan_level.user_type === 'company');
    },
  },
  mounted() {
    // Set the initial number of items
  },
  methods: {
    modalAction(open) {
      if (open == true) {
        this.$bvModal.show("add-plan");
      } else {
        this.$bvModal.hide("add-plan");
      }
    },
    async dialogSuccess() {
      this.modalAction(false);
      await this.loadData();
    },
    updatePage(page) {
      this.loadData(page);
    },
    updatePageLimit() {
      this.loadData(1, this.perPage, null);
    },
    async deletePlan(id) {
      if (confirm("Are you sure you want to delete this plan?")) {
        const api = plans.delete;

        api.id = id;
        let res = await this.generateAPI(api);
        await this.loadData();
      }
    },
    async loadData(page = 1, perPage = 10, search = "") {
      try {
        const api = plans.get;

        let res = await this.generateAPI(api);
        console.log(res.data);
        this.plansData = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    navigate(path, id) {
      this.$router.push({
        path: path,
        query: { id: id },
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body pt-0">
            <b-button class="d-flex mb-4" id="addPlan" @click="modalAction(true)" pill size="md" variant="primary">Add
              Plan
            </b-button>
            <b-tabs nav-class="nav-tabs-custom" v-model="activeTab">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Plans</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="updatePageLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table tbody-tr-class="rowClass" class="table-centered" hover :items="plansData" :fields="fields"
                    responsive="sm" :busy="$store.state.api.loading" :current-page="currentPage" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(name)="row">
                      {{ row.item.name }}
                    </template>
                    <template v-slot:cell(price)="row">
                      {{ row.item.price }}
                      <span>{{ row.item.currency || "AED" }}/ {{ row.item.frequency || 1 }} Month</span>
                    </template>
                    <template v-slot:cell(date_received)="row">
                      {{
                      !row.item.date_received
                      ? ""
                      : moment(row.item.date_received).format("l")
                      }}
                    </template>
                    <template v-slot:cell(free_trial)="row">
                      {{ row.item.free_trial }} Days
                    </template>
                    <template v-slot:cell(plan_level)="row">
                      {{ row.item.plan_level.name }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("lll") }}
                    </template>
                    <template #cell(actions)="row">
                      <i @click="deletePlan(row.item._id)" id="deleteIconBtn" class="ri-delete-bin-fill"></i>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">User Plans</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="updatePageLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table tbody-tr-class="rowClass" class="table-centered" hover :items="userPlans" :fields="fields"
                    responsive="sm" :busy="$store.state.api.loading" :current-page="currentPage" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(name)="row">
                      {{ row.item.name }}
                    </template>
                    <template v-slot:cell(price)="row">
                      {{ row.item.price }}
                      <span>{{ row.item.currency || "AED" }}/ {{ row.item.frequency || 1 }} Month</span>
                    </template>
                    <template v-slot:cell(date_received)="row">
                      {{
                      !row.item.date_received
                      ? ""
                      : moment(row.item.date_received).format("l")
                      }}
                    </template>
                    <template v-slot:cell(free_trial)="row">
                      {{ row.item.free_trial }} Days
                    </template>
                    <template v-slot:cell(plan_level)="row">
                      {{ row.item.plan_level.name }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("lll") }}
                    </template>
                    <template #cell(actions)="row">
                      <i @click="deletePlan(row.item._id)" id="deleteIconBtn" class="ri-delete-bin-fill"></i>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Company Plans</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="updatePageLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table tbody-tr-class="rowClass" class="table-centered" hover :items="companyPlans" :fields="fields"
                    responsive="sm" :busy="$store.state.api.loading" :current-page="currentPage" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn">
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(name)="row">
                      {{ row.item.name }}
                    </template>
                    <template v-slot:cell(price)="row">
                      {{ row.item.price }}
                      <span>{{ row.item.currency || "AED" }}/ {{ row.item.frequency || 1 }} Month</span>
                    </template>
                    <template v-slot:cell(date_received)="row">
                      {{
                      !row.item.date_received
                      ? ""
                      : moment(row.item.date_received).format("l")
                      }}
                    </template>
                    <template v-slot:cell(free_trial)="row">
                      {{ row.item.free_trial }} Days
                    </template>
                    <template v-slot:cell(plan_level)="row">
                      {{ row.item.plan_level.name }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("lll") }}
                    </template>
                    <template #cell(actions)="row">
                      <i @click="deletePlan(row.item._id)" id="deleteIconBtn" class="ri-delete-bin-fill"></i>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>

            </b-tabs>
            <b-modal id="add-plan" title="Add Plan" hide-footer>
              <AddPlan @success="dialogSuccess" />
            </b-modal>
          </div>

        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#addPlan {
  float: right;
  margin-top: 10px;
}
</style>
