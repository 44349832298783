export const plans = {
  create: {
    url: "plan/create",
    method: "POST",
    data: null,
  },
  get: {
    url: "admin/plan/get",
    method: "GET",
    data: null,
  },
  delete: {
    url: "plan/delete/",
    method: "DELETE",
    id: null,
  },
};
